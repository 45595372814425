*,
:after,
:before {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.header {
  z-index: 9900;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(50, 52, 61, 0.15);
  min-width: 1000px;
}

.column {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.column.lg-2 {
  width: 20%;
}

.column.lg-8 {
  width: 80%;
}

.column.opposite {
  float: right;
}

.row {
  position: relative;
}

.row::after {
  clear: both;
}

.row::after,
.row::before {
  content: ' ';
  display: table;
}

.p {
  line-height: 1.75;
}

.a {
  text-decoration: none;
  transition: all 0.3s;
}

.a:hover,
a:visited {
  transition: all 0.3s;
  text-decoration: none;
}

.header .desktop .topbar {
  font-size: 0.75rem;
  padding: 10px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #011534;
  height: 70px;
  align-items: center;
}

.header .desktop .desktop .topbar .topbar-left {
  text-align: left;
}

.header .desktop .topbar .topbar-center .topbar-center-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.header .desktop .topbar .topbar-right {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.header .desktop .topbar .topbar-logo {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 200px;
}

.header .desktop .topbar .topbar-logo-image {
  width: 100%;
}

header .desktop .topbar .topbar-logo img {
  display: block;
  width: auto;
  height: 70px;
}

.microphone-permission {
  display: table;
  position: relative;
  left: 20%;
}

.audio-permission {
  align-items: center;
}

.ms-Button-flexContainer .ms-Icon[data-icon-name="ChevronDown"] {
  display: none;
}


@media screen and (max-width: 600px) and (orientation: portrait) {
  .header {
    z-index: 9900;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(50, 52, 61, .15);
    min-width: auto;
  }

  .column {
    position: relative;
    display: flex;
    float: left;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .header .desktop .topbar {
    display: flex;
    justify-content: center;
    height: 140px !important;
    flex-direction: column;
  }

  .header .desktop .topbar .topbar-logo {
    position: relative !important;
    left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
    max-width: 200px;
  }

  .fcgxt0o {
    margin: 0;
  }
}